<template>
  <div class="card card-custom card-stretch gutter-b">
    <b-form @submit.stop.prevent="send(password)" class="form">
      <!--begin::Header-->
      <div class="card-header py-5">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">
            {{ $t("account.password.title") }}
          </h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1">{{
            $t("account.password.subtitle")
          }}</span>
        </div>
        <div class="card-toolbar">
          <b-button type="submit" variant="success" pill class="mr-2">
            {{ $t("account.password.save") }}
          </b-button>
          <button type="reset" class="btn btn-secondary">
            {{ $t("tables.clear") }}
          </button>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Form-->
      <div class="card-body">
        <b-alert
          variant="success"
          dismissible
          fade
          :show="success"
          @dismissed="success"
        >
          <p>
            {{ callGetError("success") }}
          </p>
        </b-alert>
        <b-alert
          v-if="errCode !== false"
          variant="danger"
          dismissible
          fade
          :show="errCode !== false"
          @dismissed="errCode = false"
        >
          <h4 class="alert-heading">Error</h4>
          <p>
            {{ callGetError(errCode) }}
          </p>
        </b-alert>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert text-left">
            {{ $t("account.password.current") }}
          </label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              type="password"
              class="form-control form-control-lg form-control-solid mb-2"
              id="current"
              v-model="password.currentPassword"
              :state="currentPasswordError"
              autocomplete="off"
              :placeholder="$t('account.password.current')"
            />
            <b-form-invalid-feedback
              id="current-feedback"
              v-if="currentPasswordError === false"
            >
              {{ callGetError("password_current") }}
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert text-left">
            {{ $t("account.password.new") }}
          </label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              type="password"
              class="form-control form-control-lg form-control-solid mb-2"
              id="new"
              v-model="password.newPassword"
              :state="newPasswordError"
              autocomplete="off"
              :placeholder="$t('account.password.new')"
            />
            <b-form-invalid-feedback
              id="new-feedback"
              class="text-left"
              v-if="newPasswordError === false"
            >
              {{ callGetError("password_format") }}
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert text-left">
            {{ $t("account.password.verify") }}
          </label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              type="password"
              class="form-control form-control-lg form-control-solid mb-2"
              id="verify"
              v-model="password.verify"
              :state="verifyPasswordError"
              autocomplete="off"
              :placeholder="$t('account.password.verify')"
            />
            <b-form-invalid-feedback
              id="verify-feedback"
              v-if="verifyPasswordError === false"
              class="text-left"
            >
              {{ callGetError("password_verify") }}
            </b-form-invalid-feedback>
          </div>
        </div>
      </div>
      <!--end::Form-->
    </b-form>
  </div>
</template>

<script>
import { validPassword } from "@/utils/validators";
import { resetPassword } from "@/api/auth.api";

import { getError } from "@/utils/helpers";

export default {
  name: "Password",
  data() {
    return {
      errCode: false,
      success: false,
      password: {
        newPassword: "",
        currentPassword: "",
        verify: ""
      }
    };
  },
  computed: {
    currentPasswordError() {
      return null;
    },
    newPasswordError() {
      return this.password.newPassword == ""
        ? null
        : validPassword(this.password.newPassword);
    },
    verifyPasswordError() {
      if (this.password.verify === "") return null;
      else return this.password.newPassword === this.password.verify;
    }
  },
  methods: {
    callGetError(code, params) {
      return getError(code, params);
    },
    send(password) {
      if (this.newPasswordError && this.verifyPasswordError) {
        let toSend = {
          currentPassword: Buffer.from(password.currentPassword).toString(
            "base64"
          ),
          newPassword: Buffer.from(password.newPassword).toString("base64"),
          verify: Buffer.from(password.verify).toString("base64")
        };
        resetPassword(toSend, "crud")
          .then(() => {
            this.password.newPassword = "";
            this.password.currentPassword = "";
            this.password.verify = "";
            this.errCode = false;
            this.success = true;
            this.$router.push({ name: this.$route.params.to });
            this.$root.$bvToast.toast(this.$t("errors.password_success"), {
              title: this.$t("login.form.password"),
              variant: "success",
              solid: true,
              toaster: "b-toaster-top-center"
            });
          })
          .catch(err => {
            if (err.response.data.records.errorCode === 412) {
              this.errCode = err.response.data.records.applicationCode;
              this.password.newPassword = "";
              this.password.currentPassword = "";
              this.password.verify = "";
            }
          });
      }
    }
  }
};
</script>
