<template>
  <div class="card card-custom card-stretch gutter-b">
    <b-form @submit.stop.prevent="send(account)" class="form">
      <!--begin::Header-->
      <div class="card-header py-5">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">
            {{ $t("account.account.title") }}
          </h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1">{{
            $t("account.account.subtitle")
          }}</span>
        </div>
        <div class="card-toolbar">
          <b-button type="submit" variant="success" pill class="mr-2">
            {{ $t("account.account.save") }}
          </b-button>
          <button type="reset" class="btn btn-secondary">
            {{ $t("account.account.cancel") }}
          </button>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Form-->
      <div class="card-body">
        <b-alert
          variant="success"
          dismissible
          fade
          :show="success"
          @dismissed="success"
        >
          <p>
            {{ callgetError("success") }}
          </p>
        </b-alert>
        <b-alert
          v-if="errCode !== false"
          variant="danger"
          dismissible
          fade
          :show="errCode !== false"
          @dismissed="errCode = false"
        >
          <h4 class="alert-heading">Error</h4>
          <p>
            {{ callgetError(errCode) }}
          </p>
        </b-alert>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert text-left">
            {{ $t("account.account.language") }}
          </label>
          <div class="col-lg-9 col-xl-6">
            <Multiselect
              v-model="account.language"
              :options="languages"
              :required="true"
              :searchable="true"
              :closeOnSelect="true"
              label="text"
              trackBy="value"
              value="value"
              :multiple="false"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              :placeholder="$t('account.account.language')"
              class="form-control-solid mb-2"
              id="current"
            />
            <b-form-invalid-feedback
              id="current-feedback"
              v-if="languageError === false"
            >
              {{ callgetError("language") }}
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-group row" v-if="timezones.length > 0">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert text-left">
            {{ $t("account.account.timezone") }}
          </label>
          <div class="col-lg-9 col-xl-6">
            <Multiselect
              v-model="account.timezone"
              :options="timezones"
              :required="true"
              :searchable="true"
              :closeOnSelect="true"
              :multiple="false"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              :placeholder="$t('account.account.timezone')"
              class="form-control-solid mb-2"
              id="current"
            />
            <b-form-invalid-feedback
              id="current-feedback"
              v-if="timezoneError === false"
            >
              {{ callgetError("timezone") }}
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="form-group mb-0 row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label text-left">
            {{ $t("account.account.notifications") }}
          </label>
          <div class="col-lg-9 col-xl-6 d-flex justify-content-end">
            <label>
              <toggle-button
                name="notifications"
                v-model="account.notifications"
                :value="true"
                :labels="{ checked: 'YES', unchecked: 'NO' }"
              />
              <span></span>
            </label>
          </div>
        </div>
      </div>
      <!--end::Form-->
    </b-form>
  </div>
</template>

<script>
import _ from "lodash";

import Multiselect from "vue-multiselect";
import { ToggleButton } from "vue-js-toggle-button";

import { getError } from "@/utils/helpers";

export default {
  name: "Account",
  components: { Multiselect, ToggleButton },
  data() {
    return {
      errCode: false,
      success: false,
      account: {
        language: {
          text: this.$t(
            `generic.languages.${localStorage.getItem("language")}`
          ),
          value: localStorage.getItem("language")
        },
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      timezones: []
    };
  },
  computed: {
    languageError() {
      if (this.account.language === "") return null;
      else return true;
    },
    timezoneError() {
      if (this.account.timezone === "") return null;
      else return true;
    },
    languages() {
      var result = [];
      _.each(Object.keys(this.$i18n.messages), lang => {
        result.push({
          text: this.$t(`generic.languages.${lang}`),
          value: lang
        });
      });
      return result;
    }
  },
  methods: {
    callgetError(code, params) {
      return getError(code, params);
    },
    send(account) {
      var toSend = {
        timezone: account.timezone,
        language: account.language.value,
        notifications: { email: account.notifications ? "YES" : "NO" }
      };
      console.log(toSend);
    }
  },
  mounted() {
    this.axios.get("/misc/timezones").then(response => {
      this.timezones = response.data.records;
    });
  }
};
</script>
