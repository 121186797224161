<template>
  <!--begin::Dashboard-->
  <div class="d-flex justify-content-center align-items-center">
    <!--begin::Row-->
    <div class="d-flex flex-column w-100">
      <div class="d-flex my-4">
        <b-button
          tag="a"
          @click="prevRoute"
          size="sm"
          pill
          variant="secondary"
          class="font-weight-bolder mr-2 text-uppercase"
        >
          <div class="d-flex">
            <i class="fa fa-chevron-left" />
            <span style=" white-space: nowrap">
              {{ $t("generic.buttons.back") }}
            </span>
          </div>
        </b-button>
      </div>
      <div class="d-flex flex-row" style="width: 100%;">
        <div
          class="flex-row-auto offcanvas-mobile w-250px w-xxl-350px"
          id="kt_profile_aside"
        >
          <!--begin::Profile Card-->
          <div class="card card-custom card-stretch gutter-b">
            <!--begin::Body-->
            <div class="card-body pt-4">
              <!--begin::Nav-->
              <div
                class="navi navi-bold navi-hover navi-active navi-link-rounded"
              >
                <div class="navi-item mb-2" v-for="tab in tabs" :key="tab.id">
                  <a
                    @click="changeTab(tab.id)"
                    :class="
                      'navi-link py-4 ' + (active === tab.id ? 'active' : '')
                    "
                    style="cursor: pointer"
                  >
                    <span class="navi-icon mr-2">
                      <span class="svg-icon">
                        <simple-svg
                          :src="
                            require(`@/assets/images/icons/${tab.icon}.svg`)
                          "
                        />
                        <!--end::Svg Icon-->
                      </span>
                    </span>
                    <span class="navi-text font-size-lg text-left">
                      {{ $t(`account.menu.${tab.id}`) }}
                    </span>
                  </a>
                </div>
              </div>
              <!--end::Nav-->
            </div>
            <!--end::Body-->
          </div>
          <!--end::Profile Card-->
        </div>
        <div class="flex-row-fluid ml-lg-8">
          <password v-if="active === 'password'" />
          <account v-if="active === 'account'" />
        </div>
      </div>
    </div>
  </div>

  <!--end::Row-->
  <!--end::Dashboard-->
</template>

<script>
import Password from "@/components/profile/Password.component";
import Account from "@/components/profile/Account.component";
import { SimpleSVG } from "vue-simple-svg";

export default {
  name: "Profile",
  components: {
    Password,
    Account,
    "simple-svg": SimpleSVG
  },
  data() {
    return {
      previous: this.$route.params.to,
      active: this.$route.params.option || "account",
      tabs: [
        {
          id: "password",
          icon: "shield-user"
        }
      ]
    };
  },
  methods: {
    changeTab(value) {
      this.active = value;
    },
    prevRoute() {
      this.$router.go(-1);
    }
  },
  watch: {
    "$route.params.option": {
      handler: function(option) {
        this.changeTab(option);
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
